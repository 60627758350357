@charset "utf-8";
@import "lib/reset.min.css";
@import "_vars.sass";
@import "lib/slick.css";
@import "lib/slick-theme.css";

@media only screen and (min-width: $breakpoint1) {

  .is-sp {
    display: none !important; }

  html {
 }    // overflow-y: scroll;

  body {
    color: black;
    background: $white;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Noto Serif JP', serif;
    font-feature-settings: "palt";
    overflow-x: hidden;
    &.menu {
      overflow: hidden; } }

  a {
    color: inherit;
    transition: opacity 0.2s;
    text-decoration: none;
    &:hover {
      opacity: 0.5;
      text-decoration: none; } }
  li {
    list-style: none; }

  #modal-item,
  #modal-photo {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background: rgba(0,105,185,0.8);
    transition: opacity 0.2s ease-in;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.hide {
      opacity: 0.0;
      pointer-events: none; }
    .movie-area {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 660px;
      width: 100%;
      transform: translate(-50%,-50%);
      p {
        margin-top: 30px;
        text-align: center;
        font-size: 20px;
        color: white;
        &.price {
          margin-top: 10px;
          font-size: 24px; } }
      img {
        width: 660px; } }

    .btn_photo_close,
    .btn_photo_next,
    .btn_photo_prev {
      display: block;
      text-align: center;
      position: absolute;
      width: 45px;
      height: 45px;
      background: white;
      border-radius: 45px;
      display: block;
      transition: opacity 1.0s $easeOutQuint;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%); } }

    .btn_photo_close {
      top: 36px;
      right: 36px; }
    .btn_photo_next, {
      top: 50%;
      right: 50%;
      transform: translate(411px,-50%); }
    .btn_photo_prev {
      top: 50%;
      left: 50%;
      transform: translate(-411px,-50%); }

    .slick-dots {
      text-align: center;
      li {
        width: 40px;
        overflow: hidden; }
      li button:before {
        background: #84B7DE; }
      li.slick-active button {
        background: white; } } }


  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: $white;
    transition: opacity 0.8s;
    &.hide {
      pointer-events: none;
      opacity: 0.0; }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: 0.4s $easeOutSine;
      &.show {
        opacity: 1.0; } } }

  header {
    font-family: 'Poppins', 'Noto Sans JP', serif;
    line-height: 1.0;
    font-weight: 400;
    color: $black;
    transition: 0.8s $easeOutSine;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    .menu {
      display: block;
      width: 100%;
      background: #FAFAFA;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 2;
      text-align: center;
      nav {
        ul {
          li {
            display: inline-block;
            letter-spacing: 0.25;
            a {
              padding: 29px 10px;
              display: block;
              font-weight: 500; } } } } }
    >.inner {
      width: 1124px;
      margin: 0 auto;
      position: relative;
      top: 0px;
      margin-top: 110px;
      z-index: 1;
      .sub {
        // position: absolute
        a {
          color: $fate;
          font-weight: 500; }
        .official {
          display: inline-block; }
        .share {
          margin-left: 15px;
          display: inline-block;
          a {
            margin-right: 15px; } } }
      .outline {
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: 12px; } } }

  footer {
    border-top: 1px solid $gold;
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;
    color: $black;
    .pr {
      font-family: 'Poppins', 'Noto Sans JP', serif;
      font-size: 12px;
      margin-bottom: 30px; }
    .fate15 {}
    .links {
      margin-top: 30px;
      color: $gold;
      margin-bottom: 36px;
      font-size: 20px;
      line-height: 1.0;
      a {
        display: inline-block;
        margin: 0px 12px; } }
    .notice {
      font-size: 12px;
      line-height: 1.75; }
    .copy {
      line-height: 1.75;
      font-size: 12px; } }

  .container {
    position: absolute;
    top: 0px;
    width: 100%;
    margin-top: 173px; }

  body.index {
    header {
      opacity: 0;
      &.show {
        opacity: 1; } }
    .container {
      text-align: center; }
    section.kv {
      height: 1228px;
      position: relative;
      .kvs {
        .kv {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%,0px);
          opacity: 0;
          transition: 2.0s $easeOutSine;
          &.show {
            opacity: 1.0; } } }
      .logos {
        .logo {
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translate(-50%,0px);
          opacity: 0;
          transition: 2.0s $easeOutSine;
          &.show {
            opacity: 1.0; } } } }

    section.outline {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      font-weight: 400;
      > .lead {
        font-size: 24px;
        color: $black;
        line-height: 2.0; }
      > .date {
        margin-top: 80px;
        font-size: 40px;
        color: $gold; }
      > .place {
        margin-top: 37px;
        font-size: 20px; }
      > .access {
        margin-top: 18px;
        font-size: 16px;
        a {
          color: $fate; } }
      >.themes {
        width: 700px;
        margin: 0 auto;
        margin-top: 70px;
        text-align: left;
        .theme {
          line-height: 24px;
          &.fate {
            color: $fate; }
          &.ubw {
            color: $ubw; }
          &.hf {
            color: $hf; }
          &.last-child {
            margin-bottom: 0px; }
          .title {
            font-size: 20px;
            margin-bottom: 20px;
            float: left;
            width: 300px; }
          .date {
            font-size: 24px;
            float: left;
            width: 400px; } } } }

    section.ticket {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      color: $black;
      .title {
        font-size: 14px;
        font-family: 'Poppins', 'Noto Sans JP', serif;
        letter-spacing: 0.3em;
        margin-bottom: 10px;
        line-height: 1.0; }
      .titlesub {
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 1.75;
        font-weight: 500; }
      .l {
        font-size: 40px;
        font-weight: 300; }
      .link {
        font-size: 16px;
        line-height: 20px;
        margin-top: 30px;
        a {
          margin-left: 20px;
          font-size: 20px;
          color: $fate; } }
      .note {
        font-size: 20px;
        line-height: 20px;
        margin-top: 30px;
        color: $gold;
        span {
          font-size: 16px; } } }

    section.feed {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      text-align: left;
      > .inner {
        width: 1200px;
        margin: 0 auto;
        .tw {
          float: left;
          width: 580px; }
        .news {
          float: left;
          width: 580px;
          margin-left: 40px;
          border-top: 1px solid $black;
          color: $black;
          .title {
            font-size: 12px;
            font-family: 'Poppins', 'Noto Sans JP', serif;
            letter-spacing: 0.3em;
            line-height: 1.0;
            color: $black;
            margin-top: 30px;
            margin-bottom: 30px;
            text-align: center; }
          .items {
            .item {
              font-size: 0px;
              .date {
                display: inline-block;
                width: 115px;
                font-size: 16px;
                line-height: 1.75;
                vertical-align: top;
                padding-top: 6px;
                padding-bottom: 6px; }
              .body {
                display: inline-block;
                width: 465px;
                font-size: 16px;
                border-top: 1px solid $black;
                line-height: 1.75;
                padding-top: 6px;
                padding-bottom: 6px;
                vertical-align: top;
                a {
                  color: $fate; } } } }
          .more {
            margin-top: 30px;
            border-top: 1px solid $black;
            border-bottom: 1px solid $black;
            a {
              color: $fate;
              display: block;
              font-size: 20px;
              line-height: 1.75;
              padding-top: 15px;
              padding-bottom: 15px; } } } } }

    section.intro {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      color: $fate;
      .title {
        font-size: 14px;
        font-family: 'Poppins', 'Noto Sans JP', serif;
        letter-spacing: 0.3em;
        margin-bottom: 30px;
        line-height: 1.0;
        color: $black; }
      p {
        font-size: 20px;
        line-height: 42px;
        &.l {
          font-size: 24px;
          margin-bottom: 1em; }
        span {
          font-size: 110%; } } } }

  section.title {
    text-align: left;
    >.inner {
      width: 1124px;
      margin: 0 auto;
      position: relative;
      h1 {
        line-height: 1.0;
        font-size: 40px;
        font-weight: 400;
        color: $fate;
        padding-bottom: 70px; }
      a {
        position: absolute;
        top: 0px;
        right: 0px; } } }

  body.exhibition {
    .container {
      margin-top: 193px; }

    section.exhibitions {
      // padding-top: 60px
      border-top: 1px solid $gold;
      > .inner {
        width: 890px;
        margin: 0 auto;
        .items {
          .item {
            border-top: 1px solid $gold;
            color: $gold;
            padding: 60px 0px;
            &:first-child {
              border-top: none; }
            .title {
              font-size: 40px;
              line-height: 1.0;
              margin-bottom: 30px; }
            .desc {
              font-size: 20px;
              line-height: 2.0; }
            .slide {
              margin-top: 60px; } } } } } }

  body.qa,
  body.news {
    .container {
      margin-top: 193px; }
    section.more {
      > .inner {
        width: 890px;
        margin: 0 auto;
        border-top: 1px solid $gold;
        a {
          padding-top: 60px;
          padding-bottom: 60px;
          font-size: 24px;
          display: block;
          color: $fate; } } }
    section.news-list {
      border-top: 1px solid $gold;
      > .inner {
        width: 890px;
        margin: 0 auto;
        .items {
          .item {
            border-top: 1px solid $gold;
            color: $black;
            padding: 60px 0px;
            font-size: 0px;
            &:first-child {
              border-top: none; }
            > .info {
              font-size: 24px;
              width: 230px;
              display: inline-block;
              vertical-align: top;
              line-height: 1.75; }
            > .main {
              font-size: 16px;
              width: 660px;
              display: inline-block;
              vertical-align: top;
              >.inner {
                .head {
                  font-size: 20px;
                  color: $fate;
                  margin-bottom: 15px;
                  font-weight: 400;
                  line-height: 1.75; }
                .desc {
                  margin-bottom: 30px;
                  font-weight: 400;
                  line-height: 1.75;
                  word-break: break-all;
                  &:last-child {
                    margin-bottom: 0px; }
                  span {
                    color: $fate; }
                  figure {
                    text-align: center;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    background: #EEEEEE;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    img {
                      font-size: 0px;
                      max-width: 100%;
                      height: auto; } }
                  iframe {
                    width: 100%; }
                  a {
                    color: $fate;
                    word-break: break-all; } } } } } } } } }

  body.cafe {
    .container {
      margin-top: 193px; }
    section.cafe {
      &:nth-child(2) {
        border-top: 1px solid $gold;
        > .inner {
          border-top: none; } }
      > .inner {
        padding-top: 60px;
        width: 1124px;
        margin: 0 auto;
        border-top: 1px solid $gold;
        .lead {
          margin-bottom: 30px;
          font-size: 20px;
          color: $fate;
          line-height: 2; }
        .desc {
          margin-bottom: 60px;
          font-size: 16px;
          color: $black;
          line-height: 1.75;
          span {
            font-size: 12px; } }
        .items {
          font-size: 0px;
          letter-spacing: -10000em;
          .item {
            color: $black;
            font-size: 20px;
            letter-spacing: 0em;
            display: inline-block;
            width: 544px;
            margin-bottom: 60px;
            &:nth-child(even) {
              margin-left: 36px; }
            figure {
              width: 100%;
              margin-bottom: 30px;
              img {
                width: 100%; } }
            .title {
              margin-bottom: 10px;
              font-size: 20px;
              span {
                font-size: 12px; } }
            .price {
              font-size: 24px;
              span {
                font-size: 12px; } } } } } } }

  body.special {
    .container {
      margin-top: 193px; }
    section.cm {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      color: $black;
      text-align: center;
      > .inner {
        .title {
          font-size: 40px;
          margin-bottom: 10px;
          line-height: 1.5;
          color: $fate; }
        .titlesub {
          font-size: 16px;
          margin-bottom: 60px;
          font-weight: 500;
          line-height: 1.5;
          color: $fate; }
        >.videos {
          text-align: center;
          width: 892px;
          margin: 0 auto;
          iframe {
            margin-bottom: 60px;
            display: block;
            width: 892px;
            height: 502px;
            &:last-child {
              margin-bottom: 0px; } } } } }

    section.boards {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      color: $black;
      text-align: center;
      > .inner {
        .title {
          font-size: 40px;
          margin-bottom: 10px;
          line-height: 1.5;
          color: $fate; }
        .titlesub {
          font-size: 16px;
          margin-bottom: 60px;
          font-weight: 500;
          line-height: 1.5;
          color: $fate; }
        .items {
          text-align: left;
          width: 1124px;
          margin: 0 auto;
          font-size: 0px;
          letter-spacing: -10000em;
          .item {
            text-align: center;
            display: inline-block;
            vertical-align: top;
            font-size: 0px;
            letter-spacing: 0.0em;
            width: 196px;
            margin-left: 36px;
            margin-bottom: 60px;
            &:nth-child(5n+1) {
              margin-left: 0px; }
            figure {
              width: 196px;
              margin: 0 auto;
              img {
                width: 100%; } }
            .name {
              font-size: 12px;
              margin-top: 30px; } } } } } }

  body.goods {
    .container {
      margin-top: 193px; }
    section.goods {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      color: $black;
      text-align: center;
      > .inner {
        .items {
          text-align: left;
          width: 1124px;
          margin: 0 auto;
          font-size: 0px;
          letter-spacing: -10000em;
          .item {
            text-align: center;
            display: inline-block;
            vertical-align: top;
            font-size: 0px;
            letter-spacing: 0.0em;
            width: 196px;
            margin-left: 36px;
            margin-bottom: 60px;
            &:nth-child(5n+1) {
              margin-left: 0px; }
            figure {
              width: 196px;
              margin: 0 auto;
              img {
                width: 100%; } }
            .slide {
              display: none; }
            .name {
              font-size: 12px;
              margin-top: 30px;
              // height: 4.5em
              line-height: 1.5; }
            .price {
              font-size: 20px;
              margin-top: 10px; } } } } } } }


