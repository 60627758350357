@charset "utf-8";
@import "lib/reset.min.css";
@import "_vars.sass";

@media only screen and (max-width: $breakpoint1  - 1) {

  .is-pc {
    display: none !important; }

  html {
    overflow-y: scroll; }

  body {
    color: black;
    background: $white;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "palt";
    font-family: 'Noto Serif JP', serif;
    overflow-x: hidden;
    &.menu {
      overflow: hidden; } }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: none; } }
  li {
    list-style: none; }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: $white;
    transition: opacity 0.8s;
    &.hide {
      pointer-events: none;
      opacity: 0.0; }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: 0.4s $easeOutSine;
      &.show {
        opacity: 1.0; } } }

  #modal-item,
  #modal-photo {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background: rgba(0,105,185,0.8);
    transition: opacity 0.2s ease-in;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.hide {
      opacity: 0.0;
      pointer-events: none; }
    .movie-area {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100vw;
      width: 100%;
      transform: translate(-50%,-50%);
      .btns {
        text-align: center;
        margin-top: 30px; }
      p {
        margin-top: 30px;
        text-align: center;
        font-size: 16px;
        color: white;
        &.price {
          margin-top: 10px;
          font-size: 20px; } }
      img {
        width: 100vw; } }

    .btn_photo_close,
    .btn_photo_next,
    .btn_photo_prev {
      display: block;
      text-align: center;
      position: absolute;
      width: 45px;
      height: 45px;
      background: white;
      border-radius: 45px;
      display: block;
      transition: opacity 1.0s $easeOutQuint;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%); } }

    .btn_photo_close {
      bottom: 40px;
      right: 15px;
      z-index: 1; }
    .btn_photo_next, {
      position: relative;
      display: inline-block;
      margin-left: 15px; }
    .btn_photo_prev {
      position: relative;
      display: inline-block; }

    .slick-dots {
      text-align: center;
      li {
        width: 40px;
        overflow: hidden; }
      li button:before {
        background: #84B7DE; }
      li.slick-active button {
        background: white; } } }

  header {
    font-family: 'Poppins', 'Noto Sans JP', serif;
    line-height: 1.0;
    font-weight: 400;
    color: $black;
    transition: 0.8s $easeOutSine;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    .sub {
      margin-top: 20px;
      margin-left: 20px;
      font-size: 12px;
      line-height: 2;
      a {
        color: $fate; } }
    .outline {
      margin-left: 20px;
      line-height: 2;
      font-size: 12px;
      a {
        color: $fate; } }
    .menu {

      pointer-events: none;
      opacity: 0.0;
      transition: opacity 0.4s $easeOutQuart;
      &.show {
        pointer-events: auto;
        opacity: 1.0; }

      position: fixed;
      z-index: 100;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background: #82D2FF;
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      padding: 40px;
      > .menu-inner {
        display: table;
        text-align: center;
        width: 100%;
        height: 100%;
        > .menu-content {
          display: table-cell;
          vertical-align: middle;
          width: 100%;
          height: 100%;
          position: relative;
          text-align: center;
          color: white;
          p {
            margin-top: 60px;
            font-size: 12px;
            line-height: 1.75; }
          nav {
            li {
              font-size: 24px;
              margin-bottom: 24px;
              font-weight: 500;
              letter-spacing: 0.025em;
              &:last-child {
                margin-bottom: 0px; } } } } }
      .btn_close {
        position: absolute;
        bottom: 40px;
        right: 15px;
        display: block;
        background: #fff;
        width: 45px;
        height: 45px;
        border-radius: 45px;
        margin-left: 15px;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%); } } }

    .share {
      position: fixed;
      bottom: 40px;
      right: 15px;
      z-index: 1;
      font-size: 0px;
      a {
        display: inline-block;
        background: $fate;
        width: 45px;
        height: 45px;
        border-radius: 45px;
        position: relative;
        margin-left: 15px;
        font-size: 14px;
        &.btn_menu {
          background: $black; }
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%); } } } }

  footer {
    border-top: 1px solid $gold;
    padding-top: 60px;
    padding-bottom: 90px;
    text-align: center;
    color: $black;
    .pr {
      font-family: 'Poppins', 'Noto Sans JP', serif;
      font-size: 12px;
      margin-bottom: 30px; }
    .fate15 {
      img {
        width: 106px; } }
    .links {
      margin-top: 30px;
      color: $gold;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 1.0;
      a {
        display: block;
        margin-bottom: 20px; } }
    .notice {
      font-size: 12px;
      line-height: 2; }
    .copy {
      font-size: 12px;
      line-height: 2; } }

  .container {
    position: absolute;
    top: 0px;
    width: 100%;
    margin-top: 114px; }


  body.index {
    header {
      opacity: 0;
      &.show {
        opacity: 1; } }
    .container {
      text-align: center; }
    section.kv {
      height: 697px;
      position: relative;
      .kv {
        width: 100;
        height: 509px;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%,0px);
        opacity: 0;
        transition: 2.0s $easeOutSine;
        &.show {
          opacity: 1.0; }
        &.kv01 {
          img {
            transform: translate(-40%,0px); } }
        &.kv02 {
          img {
            transform: translate(-46%,0px); } }
        &.kv03 {
          img {
            transform: translate(-50%,0px); } }
        img {
          width: 720px;
          position: absolute;
          top: 0px;
          left: 50%; } } }
    .logos {
      width: 100%;
      .logo {
          width: 100vw;
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translate(-50%,0px);
          opacity: 0;
          transition: 0.8s $easeOutSine;
          &.show {
            opacity: 1.0; }
          img {
            width: calc(100% - 30px);
            max-width: 255px; } } }

    section.outline {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      font-weight: 400;
      > .lead {
        font-size: 18px;
        color: $black;
        line-height: 2.0; }
      > .date {
        margin-top: 60px;
        font-size: 40px;
        color: $gold;
        line-height: 1.5; }
      > .place {
        margin-top: 30px;
        font-size: 17px; }
      > .access {
        margin-top: 10px;
        font-size: 14px;
        a {
          color: $fate; } }
      >.themes {
        margin: 0 auto;
        margin-top: 60px;
        .theme {
          line-height: 1.5;
          margin-bottom: 30px;
          &.fate {
            color: $fate; }
          &.ubw {
            color: $ubw; }
          &.hf {
            color: $hf; }
          &.last-child {
            margin-bottom: 0px; }
          .title {
            font-size: 20px;
            margin-bottom: 10px; }
          .date {
            font-size: 24px; } } } }

    section.ticket {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      color: $black;
      .title {
        font-size: 12px;
        font-family: 'Poppins', 'Noto Sans JP', serif;
        letter-spacing: 0.3em;
        margin-bottom: 10px;
        line-height: 1.0; }
      .titlesub {
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 1.75;
        font-weight: 500; }
      .l {
        font-size: 40px;
        font-weight: 300;
        line-height: 1.5; }
      .link {
        font-size: 14px;
        line-height: 1.75;
        margin-top: 30px;
        a {
          margin-left: 20px;
          font-size: 18px;
          color: $fate; } }
      .note {
        font-size: 18px;
        line-height: 1.75;
        // margin-top: 10px
        color: $gold;
        span {
          font-size: 14px; } } }

    section.feed {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      text-align: left;
      > .inner {
        padding-left: 15px;
        padding-right: 15px;
        .news {
          color: $black;
          .title {
            font-size: 12px;
            font-family: 'Poppins', 'Noto Sans JP', serif;
            letter-spacing: 0.3em;
            line-height: 1.0;
            color: $black;
            margin-bottom: 30px;
            text-align: center; }
          .items {
            .item {
              font-size: 16px;
              border-top: 1px solid $black;
              padding-top: 10px;
              padding-bottom: 10px;
              line-height: 1.75;
              .body {
                a {
                  color: $fate; } } } }
          .more {
            border-top: 1px solid $black;
            border-bottom: 1px solid $black;
            a {
              color: $fate;
              display: block;
              font-size: 20px;
              line-height: 1.75;
              padding-top: 15px;
              padding-bottom: 15px; } } } } }

    section.intro {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      color: $fate;
      .title {
        font-size: 12px;
        font-family: 'Poppins', 'Noto Sans JP', serif;
        letter-spacing: 0.3em;
        margin-bottom: 30px;
        line-height: 1.0;
        color: $black; }
      p {
        font-size: 18px;
        line-height: 36px;
        &.l {
          font-size: 24px;
          margin-bottom: 1em; }
        span {
          font-size: 110%; } } } }

  section.title {
    text-align: left;
    >.inner {
      padding-left: 15px;
      padding-right: 15px;
      position: relative;
      h1 {
        line-height: 1.5;
        font-size: 40px;
        font-weight: 400;
        color: $fate;
        padding-bottom: 30px; }
      a {
        position: absolute;
        top: 0px;
        right: 15px;
        img {
          width: 78px; } } } }

  body.exhibition {
    section.exhibitions {
      border-top: 1px solid $gold;
      > .inner {
          .items {}
          .item {
            border-top: 1px solid $gold;
            color: $gold;
            padding: 60px 0px;
            &:first-child {
              border-top: none; }
            .title {
              padding-left: 15px;
              padding-right: 15px;
              font-size: 31px;
              line-height: 1.5;
              margin-bottom: 20px; }
            .desc {
              padding-left: 15px;
              padding-right: 15px;
              font-size: 16px;
              line-height: 2.0; }
            .slide {
              margin-top: 30px;
              .slick-dots {
                margin-left: 15px;
                margin-top: 15px;
                width: auto;
                li {
                  width: 40px;
                  overflow: hidden; } }
              img {
                width: 100%; } } } } } }

  body.qa,
  body.news {
    section.more {
      > .inner {
        margin: 0 auto;
        a {
          border-top: 1px solid $gold;
          padding-top: 30px;
          padding-bottom: 30px;
          margin-left: 15px;
          margin-right: 15px;
          font-size: 20px;
          display: block;
          color: $fate; } } }
    section.news-list {
      border-top: 1px solid $gold;
      > .inner {
        padding-left: 15px;
        padding-right: 15px;
        .items {
          .item {
            border-top: 1px solid $gold;
            color: $black;
            padding: 30px 0px;
            font-size: 0px;
            &:first-child {
              border-top: none; }
            > .info {
              font-size: 20px;
              vertical-align: top;
              line-height: 1.75;
              margin-bottom: 30px; }
            > .main {
              font-size: 16px;
              vertical-align: top;
              >.inner {
                .head {
                  font-size: 16px;
                  color: $fate;
                  margin-bottom: 15px;
                  font-weight: 400;
                  line-height: 1.75; }
                .desc {
                  margin-bottom: 30px;
                  font-weight: 400;
                  line-height: 1.75;
                  word-break: break-all;
                  &:last-child {
                    margin-bottom: 0px; }
                  span {
                    color: $fate; }
                  figure {
                    text-align: center;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    background: #EEEEEE;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    img {
                      font-size: 0px;
                      max-width: 100%;
                      height: auto; } }
                  iframe {
                    width: 100%; }
                  a {
                    color: $fate;
                    word-break: break-all; } } } } } } } } }

  body.news {
    section.news-list {
      border-top: 1px solid $gold;
      > .inner {
        .items {
          .item {
            > .info {
              margin-bottom: 10px; }
            > .main {
              >.inner {
                .head {
                  font-size: 20px; } } } } } } } }

  body.cafe {
    section.cafe {
      border-top: 1px solid $gold;
      &:nth-child(2) {
        border-top: 1px solid $gold;
        > .inner {
          border-top: none; } }
      > .inner {
        padding-top: 60px;
        .lead {
          margin-bottom: 30px;
          font-size: 20px;
          color: $fate;
          line-height: 1.5;
          padding-left: 15px;
          padding-right: 15px; }
        .desc {
          margin-bottom: 60px;
          font-size: 14px;
          color: $black;
          line-height: 1.75;
          padding-left: 15px;
          padding-right: 15px;
          span {
            font-size: 12px; } }
        .items {
          font-size: 0px;
          letter-spacing: -10000em;
          .item {
            color: $black;
            font-size: 20px;
            letter-spacing: 0em;
            margin-bottom: 30px;
            figure {
              margin-bottom: 30px;
              img {
                width: 100%; } }
            .title {
              margin-bottom: 10px;
              font-size: 16px;
              padding-left: 15px;
              padding-right: 15px;
              em {
                font-style: normal; }
              span {
                font-size: 12px; } }
            .price {
              font-size: 20px;
              line-height: 1.5;
              padding-left: 15px;
              padding-right: 15px;
              em {
                font-style: normal; }
              span {
                font-size: 12px; } } } } } } }

  body.goods {
    section.goods {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      > .inner {
        color: $black;
        text-align: center;
        .title {
          font-size: 32px;
          margin-bottom: 10px;
          line-height: 1.5;
          color: $fate; }
        .titlesub {
          font-size: 16px;
          margin-bottom: 60px;
          font-weight: 500;
          line-height: 1.5;
          color: $fate; }
        .items {
          text-align: left;
          margin: 0 auto;
          font-size: 0px;
          letter-spacing: -10000em;
          padding-left: 15px;
          padding-right: 15px;
          .item {
            text-align: center;
            display: inline-block;
            vertical-align: top;
            font-size: 0px;
            letter-spacing: 0.0em;
            width: calc(50% - 7px);
            margin-left: 14px;
            margin-bottom: 30px;
            &:nth-child(odd) {
              margin-left: 0px; }
            figure {
              width: 100%;
              margin: 0 auto;
              img {
                width: 100%; } }
            .name {
              font-size: 12px;
              margin-top: 15px; } } } } } }

  body.special {
    section.cm {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      > .inner {
        color: $black;
        text-align: center;
        .title {
          font-size: 32px;
          margin-bottom: 10px;
          line-height: 1.5;
          color: $fate; }
        .titlesub {
          font-size: 16px;
          margin-bottom: 60px;
          font-weight: 500;
          line-height: 1.5;
          color: $fate; }
        >.videos {
          text-align: center;
          width: 100vw;
          margin: 0 auto;
          iframe {
            margin-bottom: 30px;
            display: block;
            width: 100vw;
            height: 56vw;
            &:last-child {
              margin-bottom: 0px; } } } } }

    section.boards {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      > .inner {
        color: $black;
        text-align: center;
        .title {
          font-size: 32px;
          margin-bottom: 10px;
          line-height: 1.5;
          color: $fate; }
        .titlesub {
          font-size: 16px;
          margin-bottom: 60px;
          font-weight: 500;
          line-height: 1.5;
          color: $fate; }
        .items {
          text-align: left;
          margin: 0 auto;
          font-size: 0px;
          letter-spacing: -10000em;
          padding-left: 15px;
          padding-right: 15px;
          .item {
            text-align: center;
            display: inline-block;
            vertical-align: top;
            font-size: 0px;
            letter-spacing: 0.0em;
            width: calc(50% - 7px);
            margin-left: 14px;
            margin-bottom: 30px;
            &:nth-child(odd) {
              margin-left: 0px; }
            figure {
              width: 100%;
              margin: 0 auto;
              img {
                width: 100%; } }
            .name {
              font-size: 12px;
              margin-top: 15px; } } } } } }

  body.goods {
    section.goods {
      border-top: 1px solid $gold;
      padding-top: 60px;
      padding-bottom: 60px;
      > .inner {
        color: $black;
        text-align: center;
        .items {
          text-align: left;
          margin: 0 auto;
          font-size: 0px;
          letter-spacing: -10000em;
          padding-left: 15px;
          padding-right: 15px;
          .item {
            text-align: center;
            display: inline-block;
            vertical-align: top;
            font-size: 0px;
            letter-spacing: 0.0em;
            width: calc(50% - 7px);
            margin-left: 14px;
            margin-bottom: 30px;
            &:nth-child(odd) {
              margin-left: 0px; }
            figure {
              width: 100%;
              margin: 0 auto;
              img {
                width: 100%; } }
            .slide {
              display: none; }
            .name {
              font-size: 12px;
              margin-top: 15px;
              line-height: 1.5; }
            .price {
              font-size: 10px;
              margin-top: 10px; } } } } } } }
